import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slider from "@material-ui/core/Slider";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import { actions } from "../../redux/actions";
import { settingPassword } from "../../page/games/action";
import { useTranslation } from "react-i18next";

const FormSettingPassword = ({ settingDialogPassword, userData, ...props }) => {
  const { t } = useTranslation();

  const [state, setState] = useState({
    old_password: "",
    new_password: "",
    re_password: "",
  });

  const handleClose = () => {
    props.dispatch({
      type: actions.setting,
      settingDialogPassword: false,
    });
  };

  const handleSave = async () => {
    if (state.old_password === "" || state.new_password === "") {
      props.dispatch({
        type: actions.setInitData,
        loading: false,
        errorText: t("menu.pleasefillpwd"),
        errorDialog: true,
      });
      return;
    }
    if (state.re_password !== state.new_password) {
      props.dispatch({
        type: actions.setInitData,
        loading: false,
        errorText: t("menu.pwdincorrent"),
        errorDialog: true,
      });
      return;
    }

    props.dispatch({
      type: actions.setLoading,
      loading: true,
    });

    const res = await settingPassword({
      old_password: state.old_password,
      new_password: state.new_password,
    });

    if (res.result) {
      setState({
        old_password: "",
        new_password: "",
        re_password: "",
      });
      props.dispatch({
        type: actions.setLoading,
        loading: false,
        alertMsg: true,
        alertMessage: res.message,
      });

      props.dispatch({
        type: actions.setting,
        settingDialogPassword: false,
      });
    } else {
      props.dispatch({
        type: actions.setInitData,
        loading: false,
        errorText: res.message,
        errorDialog: true,
      });
    }
  };

  const onChange = (e, n) => {
    setState({
      ...state,
      [n]: e.target.value,
    });
  };

  return (
    <>
      <Dialog open={settingDialogPassword} onClose={handleClose} aria-labelledby="form-dialog-title" PaperProps={{ style: { background: "rgba(255,255,255,0.98)" } }}>
        <DialogTitle id="form-dialog-title">{t("menu.settingnewpwd")}</DialogTitle>
        <DialogContent style={{ width: "100%", minWidth: 320, maxWidth: 500, padding: 16 }}>
          <Grid container alignItems="center">
            <TextField label={t("menu.oldpwd")} type="password" value={state.old_password} fullWidth variant="outlined" onChange={(v) => onChange(v, "old_password")} />
          </Grid>
          <Grid container alignItems="center" style={{ marginTop: 16 }}>
            <TextField label={t("menu.newpwd")} type="password" value={state.new_password} fullWidth variant="outlined" onChange={(v) => onChange(v, "new_password")} />
          </Grid>
          <Grid container alignItems="center" style={{ marginTop: 16 }}>
            <TextField
              label={t("menu.confirmpwd")}
              type="password"
              value={state.re_password}
              fullWidth
              variant="outlined"
              onChange={(v) => onChange(v, "re_password")}
              error={state.new_password !== state.re_password}
              helperText={state.new_password !== state.re_password ? t("menu.pwdincorrent") : ""}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave} color="primary" variant="contained">
            {t("menu.ok")}
          </Button>
          <Button onClick={handleClose} color="primary">
            {t("menu.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state, dis) => {
  return {
    userData: state.app.userData,
    settingDialogPassword: state.setting.settingDialogPassword,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(FormSettingPassword);

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./i18n";
import Privacy from "./page/home/policy";
import Login from "./page/login";
import Games from "./page/games";
import GamesLive from "./page/games/live";
import PlayGame from "./page/games/detail";
import Profile from "./page/profile";
// import Policy from "./page/quiz/policy";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import DGAlert from "./component/alert";
import { actions } from "./redux/actions";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 10000,
    color: "#fff",
  },
}));

function App({ loading, ...props }) {
  const classes = useStyles();

  const alertConfirm = () => {
    props.dispatch({
      type: actions.setInitData,
      errorDialog: false,
      errorText: "",
    });
  };

  const handleClose = () => {
    props.dispatch({
      type: actions.setInitData,
      alertMsg: false,
      alertMessage: "",
      alertType: "success",
    });
  };

  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <DGAlert title={"แจ้งเตือน"} description={props.errorText} open={props.errorDialog} onConfirm={alertConfirm} labelConfirm="ปิด" />

      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={props.alertMsg} onClose={handleClose} autoHideDuration={6000}>
        <Alert onClose={handleClose} severity={props.alertType}>
          {props.alertMessage}
        </Alert>
      </Snackbar>

      <Router>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/profile" element={<Profile />} />
          <Route exact path="/privacy" element={<Privacy />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/games" element={<Games />} />
          <Route exact path="/live" element={<GamesLive />} />
          <Route exact path="/games/:id" element={<PlayGame />} />
        </Routes>
      </Router>
    </>
  );
}

const mapStateToProps = (state, dis) => {
  return {
    loading: state.app.loading,
    errorText: state.app.errorText,
    errorDialog: state.app.errorDialog,
    alertMsg: state.app.alertMsg,
    alertMessage: state.app.alertMessage,
    alertType: state.app.alertType,
  };
};

export default connect(mapStateToProps)(App);

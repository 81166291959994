import React from "react";
import EventHeader from "../../container/event/header";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";


const useStyles = makeStyles((theme) => ({
  main: {
    textAlign: "center",
    padding: 20,
  },
  titleSpace: {
    margin: 16,
  },
  container: {
    width: "70%",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  items: {
    fontFamily: "AP",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "32px",
    textAlign: "left",
  },
  button: {
    marginTop: 32,
    width: "40%",
    height: "60px",
    padding: 0,
    fontSize: 22,
    fontWeight: 500,
  },
}));

const Policy = () => {
  const classes = useStyles();

  const handleStart = () => {
    window.location.href = "/login";
  };

  return (
    <div className={classes.container}>
      <br />
      <br />
      <EventHeader title="ข้อกำหนดและเงื่อนไข" name="PGJAZZ.WEB.APP" />
      <div className={classes.main}>
        <ol className={classes.items}>
          <li>
            สมาชิกต้องยอมรับเงื่อนไขและไม่มีการรับผิดชอบแต่อย่างใดหากเกิดข้อผิดพลาดจากสมาชิกทั้งสิ้น
          </li>
          <li>
            เว็บไซต์นี้เป็นการจำลองขึ้นมาเสมือนจริงเพื่อความบันเทิงเท่านั้นไม่เกี่ยวข้องกับการพนันทุกรูปแบบแต่อย่างใด
          </li>
          <li>
            เว็บไซต์นี้ไม่มีนโยบายการนำเกมส์ไปใช้ในการเล่นพนันเป็นเพียงการจำลองเพื่อความบันเทิงเท่านั้น
          </li>
          <li>
            เว็บไซต์นี้จะไม่เปิดเผยและนำข้อมูลของลูกค้าไปเผยแพร่หรือขายต่อผู้อื่น
          </li>
          <li>เว็บไซต์นี้สามารถใช้งานได้เพียง 1 ท่านต่อ 1 บัญชีเท่านั้น</li>
          <li>ลูกค้าไม่สามารถนำชื่อผู้ใช้ของตัวเองไปเปิดเผยหรือขายส่งต่อได้</li>
          <li>สมาชิกไม่สามารถขอรับเงินคืนได้หากเปิด user เข้าเล่นเกมส์แล้ว</li>
        </ol>
      </div>

      {/* <div style={{ marginTop: 16, textAlign: "center" }}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handleStart}
        >
          กลับหน้าหลัก
        </Button>
      </div> */}
    </div>
  );
};

export default Policy;

import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import { balance, luchGame } from "./action";
import { connect } from "react-redux";
import { actions } from "../../redux/actions";
import { HelmetProvider, Helmet } from "react-helmet-async";

const useStyles = makeStyles((theme) => ({
  iframe: {
    position: "absolute",
    height: "100%",
    width: "100%",
    // position: "fixed",
    // [theme.breakpoints.down("md")]: {
    //   height: "calc(100% - 64px)",
    // },
  },
}));

const Games = ({ games, ...props }) => {
  const classes = useStyles();
  const { id } = useParams();
  const [url, setUrl] = React.useState("");
  const [meta, setMeta] = React.useState({
    title: "",
    image: "",
  });

  const init = async () => {
    const load = await Promise.all([luchGame(id), balance()]);
    if (load[0].result) {
      const { Balance, expired_at, username } = load[1].payload;

      props.dispatch({
        type: actions.setGames,
        userData: { ...props.userData, balance: Balance, expired_at, username },
      });
      setUrl(load[0].payload.url);
      setMeta({
        title: load[0].payload.game,
        image: load[0].payload.image_url,
      });

      window.location.href = load[0].payload.url;
    } else {
      props.dispatch({
        type: actions.setGames,
        errorDialog: true,
        errorText: load[0].message,
      });
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <HelmetProvider context={{}}>
      <Helmet prioritizeSeoTags>
        <title>{meta.title}</title>
        <meta property="og:title" content={meta.title} />
        <meta property="og:description" content={meta.title} />
        <meta property="og:image" content={meta.image} />
      </Helmet>

      {/* <iframe
        title={meta.title}
        frameBorder="0"
        width="100%"
        height="100%"
        marginheight="0"
        marginwidth="0"
        scrolling="no"
        src={url}
        className={classes.iframe}
      ></iframe> */}
    </HelmetProvider>
  );
};

const mapStateToProps = (state, dis) => {
  return {
    games: state.app.games,
    userData: state.app.userData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(Games);

import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";

const DGAlert = ({
  title,
  description,
  labelClose,
  labelConfirm,
  open,
  close,
  onConfirm,
}) => {
  return (
    <Dialog
      style={{ minWidth: 300, width: "100%" }}
      open={open}
      onClose={close}
    >
      <DialogTitle style={{ textAlign: "center" }}>{title}</DialogTitle>
      <DialogContent style={{ minWidth: 300, boxSizing: "border-box" }}>
        <DialogContentText style={{ textAlign: "center" }}>
          {description || ""}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        style={{
          textAlign: "center",
          margin: "0 auto",
          width: "100%",
          padding: 16,
          boxSizing: "border-box",
        }}
      >
        {!!close && (
          <Button onClick={close} color="secondary">
            {labelClose || "Cancel"}
          </Button>
        )}

        {onConfirm && (
          <Button
            onClick={onConfirm}
            fullWidth
            variant="contained"
            color="primary"
          >
            {labelConfirm || "Ok"}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DGAlert;

import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Layout from "../../container/layout";
import Button from "@material-ui/core/Button";
import MuiAlert from "@material-ui/lab/Alert";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { actions } from "../../redux/actions";
import { CountDownDaily } from "@nilevia/count-down-timer-react";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import { balance, initgames } from "../games/action";
import { uploadAvata } from "./action";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { getAvata } from "../../utils";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    margin: "0 auto",
    marginTop: 60,
    zIndex: 1,
  },
  uploadbt: {
    textAlign: "center",
    margin: "16px 0 16px 0",
  },
  media: {
    maxWidth: "90%",
    height: 200,
    objectFit: "contain",
    margin: "0 auto",
  },
  main: {
    textAlign: "center",
  },
  input: {
    display: "none",
  },
  countdown: {
    textAlign: 'center'
  },
}));

const Profile = ({ games, ...props }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const [warning, setWarning] = React.useState(false);

  const init = async () => {
    props.dispatch({
      type: actions.setGames,
      loading: true,
    });

    const load = await Promise.all([initgames(), balance()]);

    if (load[0].result) {
      const { Balance, expired_at, username, currency, symbol } = load[1].payload;

      if (/^demotest_*/g.test(username)) {
        setWarning(true);
      }

      props.dispatch({
        type: actions.setGames,
        games: load[0].payload,
        userData: { ...props.userData, balance: Balance, expired_at, username, currency, symbol },
        loading: false,
      });
    } else {
      props.dispatch({
        type: actions.setGames,
        errorDialog: true,
        errorText: load[0].message,
        loading: false,
      });
    }
  };

  const handleChange = async (event) => {
    props.dispatch({
      type: actions.setGames,
      loading: true,
    });

    const data = await uploadAvata(event.target.files[0]);

    if (data.result) {
      document.cookie = "avata888=" + data.payload.avata;
      props.dispatch({
        type: actions.setGames,
        loading: false,
      });
    } else {
      props.dispatch({
        type: actions.setGames,
        errorDialog: true,
        errorText: data.message,
        loading: false,
      });
    }
  };

  const avata = getAvata();

  useEffect(() => {
    init();
  }, []);

  return (
    <Layout data={props.userData}>
      <Grid container>
        <Card className={classes.root}>
          <CardActionArea>
            <div className={classes.uploadbt}>
              <input accept="image/png" onChange={handleChange} className={classes.input} id="contained-button-file" multiple type="file" />
              <label htmlFor="contained-button-file">
                <Button startIcon={<CloudUploadIcon />} variant="contained" color="primary" component="span">
                  Upload Logo .png only
                </Button>
              </label>
            </div>

            <CardMedia component="img" className={classes.media} image={avata} title="logo" />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2" className={classes.countdown}>
                Username : {props.userData.username}
              </Typography>

              <Typography gutterBottom variant="h6" component="h2" className={classes.countdown}>
                Remaining time
              </Typography>

              <Typography variant="body2" color="textSecondary" component="p">
                <CountDownDaily className={classes.countdown} endDate={moment.utc(props.userData.expired_at).format()} dayAffix="day" hourAffix="hrs" minutesAffix="min" secondAffix="s" />
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    </Layout>
  );
};

const mapStateToProps = (state, dis) => {
  return {
    userData: state.app.userData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);

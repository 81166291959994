import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { useNavigate, useParams } from "react-router-dom";
import { initgames, balance, luchGame, luchGameLive } from "./action";
import { connect } from "react-redux";
import Link from "@material-ui/core/Link";
import { actions } from "../../redux/actions";

const useStyles = makeStyles((theme) => ({
  bg: {
    height: "100%",
    width: "100%",
    top: 0,
    left: 0,
    // backgroundImage: "url('/photo/bg.jpg')",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundColor: "#121212",
    position: "fixed",
  },
  root: {},
  main: {
    textAlign: "center",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  gameItem: {
    position: "relative",
    width: "100%",
    minWidth: 120,
    margin: 8,
    height: "auto",
    "& img": {
      width: "100%",
      padding: 8,
      [theme.breakpoints.down("sm")]: {
        // maxHeight: "230px !important",
      },
    },
    "&:hover img": {
      filter: "grayscale(.10) contrast(1.1)",
      mixBlendMode: "exclusion",
      transition: "all .5s",
      // transform: "rotateY(1turn)",

      WebkitTransform: "scale(0.9)",
    },
    "&:hover button": {
      opacity: "1",
    },
  },
  gameLists: {
    display: "flex",
    flexWrap: "wrap",
    alignContent: "baseline",
    margin: "16px 16px 16px 0",
  },
  buttonLists: {
    cursor: "pointer",
    position: "absolute",
    alignItems: "center",
    borderRadius: "4px",
    display: "inline-flex",
    flex: "0 0 auto",
    zIndex: "200",
    top: "50%",
    left: "50%",
    justifyContent: "center",
    outline: 0,
    transform: "translate(-50%,-50%)",
    background: "linear-gradient(180deg,#ffdf46,#ffdc42 23.96%,#feeb52 49.48%,#fbc316 71.87%,#f69b09)",
    padding: "0.5rem 0",
    width: "70%",
    fontWeight: "700",
    fontSize: "18px",
    color: "#212121",
    // visibility: "hidden",
    opacity: "0",
    transition: "visibility .2s,opacity .2s linear",
    height: 36,
    minWidth: 64,
    "&:before": {
      backgroundColor: "currentColor",
      borderRadius: "inherit",
      bottom: "0",
      color: "inherit",
      content: '""',
      left: "0",
      opacity: "0",
      pointerEvents: "none",
      position: "absolute",
      right: "0",
      top: "0",
      transition: "opacity .2s cubic-bezier(.4,0,.6,1)",
    },
  },
  buttonSetting: {
    cursor: "pointer",
    position: "absolute",
    alignItems: "center",
    borderRadius: "4px",
    display: "inline-flex",
    flex: "0 0 auto",
    zIndex: "200",
    top: "calc(50% + 50px)",
    left: "50%",
    justifyContent: "center",
    outline: 0,
    transform: "translate(-50%,-50%)",
    background: "#e53935",
    padding: "0.5rem 0",
    width: "70%",
    fontWeight: "700",
    fontSize: "14px",
    color: "#fff",
    // visibility: "hidden",
    opacity: "0",
    transition: "visibility .2s,opacity .2s linear",
    height: 36,
    minWidth: 64,
    "&:before": {
      backgroundColor: "currentColor",
      borderRadius: "inherit",
      bottom: "0",
      color: "inherit",
      content: '""',
      left: "0",
      opacity: "0",
      pointerEvents: "none",
      position: "absolute",
      right: "0",
      top: "0",
      transition: "opacity .2s cubic-bezier(.4,0,.6,1)",
    },
  },
  gameName: {
    fontSize: 14,
    position: "absolute",
    bottom: "-4px",
    // left: "50%",
    padding: "10px 0!important",
    boxSizing: "border-box",
    margin: 8,
    borderBottomRightRadius: "10px",
    borderBottomLeftRadius: "10px",
    // transform: "translate(-47.3%,-50%)",
    textAlign: "center",
    zIndex: "3",
    backgroundColor: "rgba(0,0,0,.7)",
    whiteSpace: "nowrap",
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "#fff",
  },
}));

const Games = ({ games, ...props }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const init = async () => {
    props.dispatch({
      type: actions.setGames,
      loading: true,
    });

    const [user, games] = await Promise.all([balance(), luchGameLive()]);
    if (games.result) {
      const { Balance, expired_at, username } = user.payload;
      // const

      props.dispatch({
        type: actions.setGames,
        games: games.payload,
        userData: { ...props.userData, balance: Balance, expired_at, username },
        loading: false,
      });
    } else {
      props.dispatch({
        type: actions.setGames,
        errorDialog: true,
        errorText: games.message,
        loading: false,
      });
    }
  };

  // const init = async () => {
  //   const load = await Promise.all([luchGame(id), balance()]);
  //   if (load[0].result) {
  //     const { Balance, expired_at, username } = load[1].payload;

  //     setUrl(load[0].payload.url);
  //     setMeta({
  //       title: load[0].payload.game,
  //       image: load[0].payload.image_url,
  //     });

  //     props.dispatch({
  //       type: actions.setGames,
  //       userData: { balance: Balance, expired_at, username },
  //     });
  //   } else {
  //     props.dispatch({
  //       type: actions.setGames,
  //       errorDialog: true,
  //       errorText: load[0].message,
  //     });
  //   }
  // };

  useEffect(() => {
    init();
  }, []);

  const settingOpenGame = (data) => {
    props.dispatch({
      type: actions.setting,
      giData: data,
      settingDialog: true,
    });
  };

  const handleLunch = async (game_id) => {
    // navigate("/games/" + game_id);

    props.dispatch({
      type: actions.setLoading,
      loading: true,
    });
    const url = await luchGame(game_id);
    if (url.result) {
      props.dispatch({
        type: actions.setLoading,
        loading: false,
      });

      // Object.assign(document.createElement("a"), {
      //   target: "_blank",
      //   rel: "noopener noreferrer",
      //   href: "/games/" + game_id,
      //   innerHTML: game_id,
      // }).click();

      // window.open("/games/" + game_id, "_blank");

      // const wopen = window.open(
      //   "/games/" + game_id,
      //   "_blank",
      //   "noopener,noreferrer"
      // );

      // if (wopen) {
      //   console.log("work");
      // } else {
      //   window.location.href = url.payload.url;
      // Object.assign(document.createElement("a"), {
      //   target: "_blank",
      //   rel: "noopener noreferrer",
      //   href: url.payload.url,
      //   innerHTML: game_id,
      // }).click();

      // url.payload.url
      // window.open(url.payload.url, "_blank", "noopener,noreferrer");
      // }
    } else {
      props.dispatch({
        type: actions.setLoading,
        loading: false,
      });
    }
  };

  return (
    <div className={classes.gameLists}>
      <div className={classes.bg} />
      {games.map((v, k) => (
        <Grid key={k} item xs={6} sm={4} md={4} lg={2}>
          <Link
            // href={`/games/${v.game_id}`}
            href={v.url}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: "none",
              display: "flex",
              color: "#212121",
              margin: 0,
            }}
            button
          >
            <div key={k} className={classes.gameItem}>
              <img
                src={v.image_url}
                style={{
                  minHeight: 230,
                  width: "100%",
                  objectFit: "cover",
                  maxHeight: 437,
                }}
                alt=""
              />
              <span className={classes.gameName}>{v.game}</span>
            </div>
          </Link>
        </Grid>
      ))}
    </div>
  );
};

const mapStateToProps = (state, dis) => {
  return {
    games: state.app.games,
    userData: state.app.userData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(Games);

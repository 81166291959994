import axios from "axios";
import Cookie from "cookie-universal";
import { getToken } from "../utils";
const cookies = Cookie();

const { CancelToken } = axios;
const source = CancelToken.source();

const apiUrl = process.env.REACT_APP_API_URL;

export const api = axios.create({
  baseURL: apiUrl,
  timeout: 50000,
  headers: {
    Accept: "application/json, text/plain, */*",
    "Content-Type": "application/json",
  },
  get cancelToken() {
    return source.token;
  },
});

api.interceptors.request.use(
  (config) => {
    const configs = { ...config };
    const token = getToken();
    if (!!token) {
      configs.headers["x-xsrf-token"] = getToken();
    }
    configs.headers["local-lang"] = cookies.get("lang") || "th";
    return configs;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => {
    if (
      !response.data.result &&
      !!response.data.token_expire &&
      response.config.url !== "web-api/v1/auth/isauth"
    ) {
      document.cookie.split(";").forEach((c) => {
        document.cookie = c
          .replace(/^ +/, "")
          .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      });
      window.location.href = "/";
    }
    return response;
  },
  (error) => {
    // Do something with response error
    return Promise.reject(error);
  }
);

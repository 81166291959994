import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slider from "@material-ui/core/Slider";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import { actions } from "../../redux/actions";
import { settingCredit } from "../../page/games/action";
import { useTranslation } from "react-i18next";

const FormSettingCredit = ({ settingDialogCredit, userData, ...props }) => {

  const { t } = useTranslation();

  const [credit, setCredit] = React.useState(userData.balance);

  const handleClose = () => {
    props.dispatch({
      type: actions.setting,
      settingDialogCredit: false,
    });
  };

  const handleSave = async () => {
    props.dispatch({
      type: actions.setLoading,
      loading: true,
    });

    const res = await settingCredit({ credit });

    if (res.result) {
      props.dispatch({
        type: actions.setLoading,
        loading: false,
        alertMsg: true,
        alertMessage: res.message,
        userData: { ...userData, balance: +credit },
      });

      props.dispatch({
        type: actions.setting,
        settingDialogCredit: false,
      });
    } else {
      props.dispatch({
        type: actions.setInitData,
        loading: false,
        errorText: res.message,
        errorDialog: true,
      });
    }
  };

  const onChange = (e) => {
    setCredit(e.target.value);
  };

  useEffect(() => {
    setCredit(userData.balance);
  }, [userData.balance]);

  return (
    <>
      <Dialog
        open={settingDialogCredit}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        PaperProps={{ style: { background: "rgba(255,255,255,0.98)" } }}
      >
        <DialogTitle id="form-dialog-title">{t("menu.settinggamecredit")}</DialogTitle>
        <DialogContent style={{ minWidth: 320, maxWidth: 400 }}>
          <Grid container spacing={2} alignItems="center">
            <TextField
              label={t("menu.credit")}
              value={credit}
              fullWidth
              variant="filled"
              onChange={onChange}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave} color="primary" variant="contained">
            {t("menu.ok")}
          </Button>
          <Button onClick={handleClose} color="primary">
            {t("menu.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state, dis) => {
  return {
    userData: state.app.userData,
    settingDialogCredit: state.setting.settingDialogCredit,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(FormSettingCredit);

import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Input from "@material-ui/core/Input";
import Slider from "@material-ui/core/Slider";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import { actions } from "../../redux/actions";
import { getSettingPg, settingPg } from "../../page/games/action";
import { useTranslation } from "react-i18next";

const FormSetting = ({ settingDialog, giData, ...props }) => {
  const { t } = useTranslation();

  const [state, setState] = React.useState({
    spinfreemax: 5,
    scatterpay: 50,
    scatterbuypay: 50,
    sketermin: 50,
    sketermax: 50,
    jackpot0: 60,
    jackpotmin: 30,
    jackpotlarge: 5,
    jackpotmax: 5,
  });

  const handleSliderChange = (_, n, name) => {
    if (n === "") {
      setState({
        ...state,
        [name]: null,
      });
      return;
    }
    const total = state.jackpot0 + state.jackpotmin + state.jackpotmax + state.jackpotlarge - state[name];

    const max = total + parseInt(n);
    if (max <= 100) {
      setState({
        ...state,
        [name]: parseInt(n),
      });
    }
  };

  const handleScatterChange = (_, n) => {
    if (n === "") {
      setState({
        ...state,
        spinfreemax: null,
      });
      return;
    }
    setState({
      ...state,
      spinfreemax: +n > 100 ? 100 : +n,
    });
  };

  const handlePayScatterChange = (_, n) => {
    if (n === "") {
      setState({
        ...state,
        scatterpay: null,
      });
      return;
    }
    setState({
      ...state,
      scatterpay: +n > 100 ? 100 : +n,
    });
  };

  const handlePayBuyScatterChange = (_, n) => {
    if (n === "") {
      setState({
        ...state,
        scatterbuypay: null,
      });
      return;
    }

    setState({
      ...state,
      scatterbuypay: +n > 100 ? 100 : +n,
    });
  };

  const handleClose = () => {
    props.dispatch({
      type: actions.setting,
      settingDialog: false,
      giData: {},
    });
  };

  const handleSave = async () => {
    props.dispatch({
      type: actions.setLoading,
      loading: true,
    });

    const save = [state.spinfreemax, state.scatterpay, state.scatterbuypay, state.jackpot0, state.jackpotmin, state.jackpotlarge, state.jackpotmax];

    const res = await settingPg({ config: save, gi: giData.game_id });

    if (res.result) {
      props.dispatch({
        type: actions.setLoading,
        loading: false,
        alertMsg: true,
        alertMessage: res.message,
      });

      props.dispatch({
        type: actions.setting,
        settingDialog: false,
        giData: {},
      });
    } else {
      props.dispatch({
        type: actions.setInitData,
        loading: false,
        errorText: res.message,
        errorDialog: true,
      });
    }
  };

  const getDefaultSetting = async () => {
    props.dispatch({
      type: actions.setLoading,
      loading: true,
    });
    const res = await getSettingPg(giData.game_id);
    const value = res.payload;
    if (value.length > 8) {
      const data = {
        spinfreemax: value[0],
        scatterpay: value[1],
        scatterbuypay: value[2],
        jackpot0: value[3],
        jackpotmin: value[4],
        jackpotlarge: value[5],
        jackpotmax: value[6],
      };
      setState({
        ...state,
        ...data,
      });
    } else {
      setState({
        spinfreemax: 5,
        scatterpay: 50,
        scatterbuypay: 50,
        sketermin: 50,
        sketermax: 50,
        jackpot0: 60,
        jackpotmin: 30,
        jackpotlarge: 5,
        jackpotmax: 5,
      });
    }

    props.dispatch({
      type: actions.setLoading,
      loading: false,
    });
  };

  useEffect(() => {
    if (giData.game_id !== undefined) {
      getDefaultSetting();
    }
  }, [giData.game_id]);

  return (
    <>
      <Dialog open={settingDialog} onClose={handleClose} aria-labelledby="form-dialog-title" PaperProps={{ style: { background: "rgba(255,255,255,0.98)" } }}>
        <DialogTitle id="form-dialog-title">
          {t("menu.settinggame")} {giData.game}
        </DialogTitle>
        <DialogContent style={{ minWidth: 320, maxWidth: 400 }}>
          <DialogContentText>{t("menu.settingresultgame")}</DialogContentText>

          <Grid container spacing={2} alignItems="center">
            {!!giData.s && (
              <Paper style={{ width: "100%", marginBottom: 16, marginTop: 16 }} elevation={3}>
                <List
                  component="nav"
                  style={{ width: "100%" }}
                  subheader={
                    <ListSubheader style={{ fontSize: 14, color: "#000", position: "unset" }} component="div">
                      {t("menu.settingrandomscatter")} {state.spinfreemax}% {t("menu.rencommend5")}
                    </ListSubheader>
                  }
                >
                  <ListItem button>
                    <div style={{ display: "flex", width: "100%" }}>
                      <Slider valueLabelDisplay="auto" value={state.spinfreemax} onChange={(e, n) => handleScatterChange(e, n)} aria-labelledby="input-slider" />
                      <Input
                        style={{ marginTop: -8, marginLeft: 24, minWidth: 60 }}
                        value={state.spinfreemax}
                        onChange={(v) => {
                          handleScatterChange(v, v.target.value);
                        }}
                        inputProps={{
                          min: 0,
                          max: 100,
                          type: "number",
                          "aria-labelledby": "input-slider",
                        }}
                      />
                    </div>
                  </ListItem>
                </List>

                <List
                  component="nav"
                  style={{ width: "100%" }}
                  subheader={
                    <ListSubheader style={{ fontSize: 14, color: "#000", position: "unset" }} component="div">
                      {t("menu.settingpayrandomscatter")} {state.scatterpay}%
                    </ListSubheader>
                  }
                >
                  <ListItem button>
                    <div style={{ display: "flex", width: "100%" }}>
                      <Slider valueLabelDisplay="auto" value={state.scatterpay} onChange={(e, n) => handlePayScatterChange(e, n)} aria-labelledby="input-slider" />
                      <Input
                        style={{ marginTop: -8, marginLeft: 24, minWidth: 60 }}
                        value={state.scatterpay}
                        onChange={(v) => {
                          handlePayScatterChange(v, v.target.value);
                        }}
                        inputProps={{
                          min: 0,
                          max: 100,
                          type: "number",
                          "aria-labelledby": "input-slider",
                        }}
                      />
                    </div>
                  </ListItem>
                </List>
                {!!giData.b && (
                  <List
                    key="scatterbuypay"
                    component="nav"
                    style={{ width: "100%" }}
                    subheader={
                      <ListSubheader style={{ fontSize: 14, color: "#000", position: "unset" }} component="div">
                        {t("menu.settingrandombuyscatter")} {state.scatterbuypay}%
                      </ListSubheader>
                    }
                  >
                    <ListItem button>
                      <div style={{ display: "flex", width: "100%" }}>
                        <Slider valueLabelDisplay="auto" value={state.scatterbuypay} onChange={(e, n) => handlePayBuyScatterChange(e, n)} />
                        <Input
                          style={{
                            marginTop: -8,
                            marginLeft: 24,
                            minWidth: 60,
                          }}
                          value={state.scatterbuypay}
                          onChange={(v) => {
                            handlePayBuyScatterChange(v, v.target.value);
                          }}
                          inputProps={{
                            min: 0,
                            max: 100,
                            type: "number",
                            "aria-labelledby": "input-slider",
                          }}
                        />
                      </div>
                    </ListItem>
                  </List>
                )}
              </Paper>
            )}

            <Paper style={{ width: "100%", marginBottom: 16 }} elevation={3}>
              <List
                component="nav"
                style={{ width: "100%" }}
                subheader={
                  <ListSubheader style={{ fontSize: 16, color: "#000", position: "unset" }} component="div">
                    {t("menu.settingfreetotal")} {state.jackpot0 + state.jackpotmin + state.jackpotlarge + state.jackpotmax}%
                  </ListSubheader>
                }
              >
                <ListItem button>
                  <ListItemText
                    primary={t("menu.settingnotpay")}
                    primaryTypographyProps={{
                      style: { fontSize: 14, marginBottom: 8 },
                    }}
                    secondary={
                      <div style={{ display: "flex", width: "100%" }}>
                        <Slider valueLabelDisplay="auto" value={state.jackpot0} onChange={(e, n) => handleSliderChange(e, n, "jackpot0")} aria-labelledby="input-slider" />

                        <Input
                          style={{
                            marginTop: -8,
                            marginLeft: 24,
                            minWidth: 60,
                          }}
                          value={state.jackpot0}
                          onChange={(v) => {
                            console.log(v.target.value);
                            handleSliderChange(v, v.target.value, "jackpot0");
                          }}
                          inputProps={{
                            min: 0,
                            max: 100,
                            type: "number",
                            "aria-labelledby": "input-slider",
                          }}
                        />
                      </div>
                    }
                  />
                </ListItem>

                <ListItem button>
                  <ListItemText
                    primary={t("menu.settingpayless")}
                    primaryTypographyProps={{
                      style: { fontSize: 14, marginBottom: 8 },
                    }}
                    secondary={
                      <div style={{ display: "flex", width: "100%" }}>
                        <Slider valueLabelDisplay="auto" value={state.jackpotmin} onChange={(e, n) => handleSliderChange(e, n, "jackpotmin")} aria-labelledby="input-slider" />

                        <Input
                          style={{
                            marginTop: -8,
                            marginLeft: 24,
                            minWidth: 60,
                          }}
                          value={state.jackpotmin}
                          onChange={(v) => {
                            handleSliderChange(v, v.target.value, "jackpotmin");
                          }}
                          inputProps={{
                            min: 0,
                            max: 100,
                            type: "number",
                            "aria-labelledby": "input-slider",
                          }}
                        />
                      </div>
                    }
                  />
                </ListItem>

                <ListItem button>
                  <ListItemText
                    primary={t("menu.settingpaynormal")}
                    primaryTypographyProps={{
                      style: { fontSize: 14, marginBottom: 8 },
                    }}
                    secondary={
                      <div style={{ display: "flex", width: "100%" }}>
                        <Slider valueLabelDisplay="auto" value={state.jackpotlarge} onChange={(e, n) => handleSliderChange(e, n, "jackpotlarge")} aria-labelledby="input-slider" />

                        <Input
                          style={{
                            marginTop: -8,
                            marginLeft: 24,
                            minWidth: 60,
                          }}
                          value={state.jackpotlarge}
                          onChange={(v) => {
                            handleSliderChange(v, v.target.value, "jackpotlarge");
                          }}
                          inputProps={{
                            min: 0,
                            max: 100,
                            type: "number",
                            "aria-labelledby": "input-slider",
                          }}
                        />
                      </div>
                    }
                  />
                </ListItem>

                <ListItem button>
                  <ListItemText
                    primaryTypographyProps={{
                      style: { fontSize: 14, marginBottom: 8 },
                    }}
                    primary={t("menu.settingpaymore")}
                    secondary={
                      <div style={{ display: "flex", width: "100%" }}>
                        <Slider valueLabelDisplay="auto" value={state.jackpotmax} onChange={(e, n) => handleSliderChange(e, n, "jackpotmax")} aria-labelledby="input-slider" />

                        <Input
                          style={{
                            marginTop: -8,
                            marginLeft: 24,
                            minWidth: 60,
                          }}
                          value={state.jackpotmax}
                          onChange={(v) => {
                            handleSliderChange(v, v.target.value, "jackpotmax");
                          }}
                          inputProps={{
                            min: 0,
                            max: 100,
                            type: "number",
                            "aria-labelledby": "input-slider",
                          }}
                        />
                      </div>
                    }
                  />
                </ListItem>
              </List>
            </Paper>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave} color="primary" variant="contained">
            {t("menu.ok")}
          </Button>
          <Button onClick={handleClose} color="primary">
            {t("menu.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state, dis) => {
  return {
    settingDialog: state.setting.settingDialog,
    giData: state.setting.giData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(FormSetting);

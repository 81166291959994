import React, { useEffect } from "react";
import clsx from "clsx";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import FormHelperText from "@material-ui/core/FormHelperText";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Typography from "@material-ui/core/Typography";
import Snackbar from "@material-ui/core/Snackbar";
import Grid from "@material-ui/core/Grid";
import DGAlert from "../../component/alert";
import Button from "@material-ui/core/Button";
import { isMobile } from "react-device-detect";
import { api } from "../../api";
import Layout from "../../container/layout";
import EventHeader from "../../container/event/header";
import { Link, useNavigate, useParams } from "react-router-dom";
import { initPage } from "../home/action";
import { inituser, isAuth, login } from "./action";
import { connect } from "react-redux";
import moment from "moment";
import { dateTH } from "../../utils";
import { actions } from "../../redux/actions";

const useStyles = makeStyles((theme) => ({
  bg: {
    // backgroundImage: "url('/photo/bg.jpg')",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center bottom",
    [theme.breakpoints.up("xs")]: {
      padding: 16,
    },
    [theme.breakpoints.down("xs")]: {
      backgroundImage: "none",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  loginDesc: {
    color: "#585251",
    fontSize: 18,
    padding: "16px 16px 0 16px",
    lineHeight: "32px",
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
      lineHeight: "28px",
    },
  },
  loginTime: {
    fontSize: 18,
    color: "#C92028",
    paddingBottom: 32,
    fontWeight: 501,
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
      lineHeight: "28px",
    },
  },
  mainLogin: {
    maxWidth: 953,
    margin: "0 auto",
    textAlign: "center",
    padding: "50px 0 64px 0",
    [theme.breakpoints.down("md")]: {
      marginTop: 0,
    },
    [theme.breakpoints.up("xs")]: {
      marginTop: 30,
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
    },
  },
  button: {
    marginTop: 60,
    width: "404px",
    height: "70px",
    padding: 0,
    fontWeight: 500,
    fontSize: 24,
    lineHeight: "32px",
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
      lineHeight: "32px",
      width: "273px",
      height: "56px",
    },
  },
  banner: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: 58,
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: 113.51,
    },
    width: "100%",
    maxWidth: 953,
    maxHeight: 250,
    objectFit: "cover",
    margin: "0 auto",
    color: "#000000",
    textAlign: "center",
    "& img": {
      width: "100%",
      maxWidth: 953,
      maxHeight: 250,
      objectFit: "cover",
    },
  },
  inputForm: {
    [theme.breakpoints.up("md")]: {
      padding: "16px 86px 32px 86px",
    },
  },
  errorText: {
    color: "#f44336",
  },
  content: {
    whiteSpace: "pre-wrap",
    fontSize: 18,
    lineHeight: "32px",
    color: "#585251",
    fontWeight: 400,
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
      lineHeight: "28px",
    },
  },
  subtitlelogin: {
    fontSize: 18,
    lineHeight: "32px",
    color: "#585251",
    [theme.breakpoints.down("xs")]: {
      padding: "0 16px 0 16px",
      fontSize: 16,
      fontWeight: 400,
      lineHeight: "32px",
    },
  },
  textheaderlogin: {
    [theme.breakpoints.down("xs")]: {
      padding: "0 16px 0 16px",
    },
  },
}));

const Login = ({ loading, errorText, errorDialog, dispatch }) => {
  const navigate = useNavigate();

  const [values, setValues] = React.useState({
    username: "",
    password: "",
    showPassword: false,
  });

  const [state, setState] = React.useState({
    error: {},
  });

  const handleLogin = async () => {
    const err = {
      username: values.username === "",
      password: values.password === "",
    };

    if (values.username === "" || values.password === "") {
      dispatch({
        type: actions.setInitData,
        errorDialog: true,
        errorText: "กรุณาใส่ ชื่อผู้ใช้และรหัสผ่าน",
      });
    } else {
      dispatch({
        type: actions.setLoading,
        loading: true,
      });
      const checklogin = await login({
        username: values.username,
        password: values.password,
      });
      if (checklogin.result) {
        document.cookie = "game888=" + checklogin.payload.token;
        document.cookie = "avata888=" + checklogin.payload.avata;
        dispatch({
          type: actions.setInitData,
          loading: false,
          userData: checklogin.payload,
        });
        navigate("/games");
      } else {
        dispatch({
          type: actions.setInitData,
          loading: false,
          errorDialog: true,
          errorText: checklogin.message,
        });
      }
    }
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSnackClose = () => {
    setState({ ...state, snack: false });
  };

  const alertConfirm = () => {
    dispatch({
      type: actions.setInitData,
      loading: false,
      errorDialog: false,
      errorText: "",
    });
  };

  const classes = useStyles();

  const checkAlredy = async () => {
    dispatch({
      type: actions.setLoading,
      loading: true,
    });
    const alr = await isAuth();

    dispatch({
      type: actions.setLoading,
      loading: false,
    });
    if (alr.payload) {
      navigate("/games");
    }
  };

  useEffect(() => {
    checkAlredy();
  }, []);

  return (
    <div className={classes.bg}>
      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={state.snack} onClose={handleSnackClose} message={state.snackMsg} />
      <DGAlert title={"แจ้งเตือน"} description={errorText} open={errorDialog} onConfirm={alertConfirm} labelConfirm="ปิด" />

      <div className={classes.mainLogin}>
        <img src="/logo/slot.png" width="380" alt="pgjzzz.win" />

        <form noValidate className={classes.inputForm} autoComplete="off">
          <TextField
            fullWidth
            placeholder="Username"
            value={values.username}
            onChange={handleChange("username")}
            style={{ maxWidth: 500, padding: 8, boxSizing: "border-box" }}
            InputLabelProps={{
              style: {
                padding: 8,
                fontSize: 18,
                transform: "none",
                color: "#292726",
              },
              shrink: true,
            }}
            InputProps={{
              disableUnderline: true,
              style: {
                marginTop: 32,
                marginBottom: 16,
                fontSize: 18,
                color: "#989898",
                borderBottom: "1px solid #dddddd",
              },
            }}
            error={state.error.username}
            helperText={state.error.username ? `please fill username` : ""}
          />

          <FormControl
            style={{
              padding: 8,
              maxWidth: 500,
              boxSizing: "border-box",
              marginTop: 32,
            }}
            fullWidth
          >
            <Input
              type={values.showPassword ? "text" : "password"}
              placeholder="Password"
              value={values.password}
              onChange={handleChange("password")}
              disableUnderline
              style={{
                marginTop: 16,
                marginBottom: 16,
                fontSize: 18,
                color: "#989898",
                borderBottom: "1px solid #dddddd",
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              labelwidth={70}
            />
            {state.error.password && <FormHelperText className={classes.errorText}>{state.error.password ? `please fill password` : ""}</FormHelperText>}
          </FormControl>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" className={classes.button} onClick={handleLogin}>
              Login
            </Button>
            <br />
            {/* <br />
            <Link to="/privacy">ข้อกำหนดและเงื่อนไข</Link> */}
            <br />
            <a href="https://line.me/R/ti/p/@026bfhii" target="_blank" rel="noopener noreferrer">
              ติดต่อเรา
            </a>
          </Grid>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state, dis) => {
  return {
    loading: state.app.loading,
    userData: state.app.userData,
    errorText: state.app.errorText,
    errorDialog: state.app.errorDialog,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);

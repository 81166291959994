import { api } from "../../api";

export const initgames = async (eventID) => {
  const { data } = await api.get("web-api/v1/pg/games").catch((error) => error.response);

  return data;
};

export const balance = async () => {
  const { data } = await api.get("web-api/v1/auth/balance").catch((error) => error.response);

  return data;
};

export const settingPg = async (raw) => {
  const { data } = await api
    .put(
      "web-api/v1/pg/setting",
      JSON.stringify({
        config: raw.config,
        gi: raw.gi,
      })
    )
    .catch((error) => error.response);

  return data;
};

export const getSettingPg = async (gi) => {
  const { data } = await api.get("web-api/v1/pg/setting/" + gi).catch((error) => error.response);

  return data;
};

export const settingCredit = async (raw) => {
  const { data } = await api
    .put(
      "web-api/v1/auth/balance",
      JSON.stringify({
        credit: raw.credit,
      })
    )
    .catch((error) => error.response);

  return data;
};

export const settingPassword = async (raw) => {
  const { data } = await api
    .put(
      "web-api/v1/auth/resetpwd",
      JSON.stringify({
        old_password: raw.old_password,
        new_password: raw.new_password,
      })
    )
    .catch((error) => error.response);

  return data;
};

export const settingClear = async () => {
  const { data } = await api.delete("web-api/v1/pg/reset").catch((error) => error.response);

  return data;
};

export const luchGame = async (game_id) => {
  const lang = localStorage.getItem("win_lang") || "th";

  const { data } = await api.post("web-api/v1/pg/lunch", { game_id, lang }).catch((error) => error.response);

  return data;
};

export const luchGameLive = async () => {
  const lang = localStorage.getItem("win_lang") || "th";

  const { data } = await api.post("web-api/v1/pg/live", { lang }).catch((error) => error.response);

  return data;
};

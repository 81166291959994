import moment from "moment";
import { actions } from "./actions";
import { getAvata } from "../utils";

const initialState = {
  initData: {},
  userData: {
    balance: 0,
    expired_at: moment().utc().format(),
    username: "",
    currency: "THB",
    symbol: "฿",
  },
  games: [],
  loading: false,
  errorText: "",
  errorDialog: false,
  alertMsg: false,
  alertMessage: "",
  alertType: "success",
};

export default function app(state = initialState, action) {
  const { type, ...etc } = action;
  switch (type) {
    case actions.setInitData:
    case actions.setLoading:
    case actions.setGames:
    case actions.setAlertMsg:
      return { ...state, ...etc };
    default:
      return state;
  }
}

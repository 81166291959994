import { api } from "../../api";
import { setCookie } from "../../utils";

export const logout = async () => {
  const { data } = await api.get("web-api/v1/auth/logout").catch((error) => error.response);
  setCookie("game888", null);
  setCookie("avata888", null);
  return data;
};

export const updateCurrency = async (raw) => {
  const { data } = await api.put("/web-api/v1/profile/currency", JSON.stringify({ currency: raw.currency, symbol: raw.symbol })).catch((error) => error.response);

  return data;
};

export const uploadAvata = async (f) => {
  const file = new FormData();

  file.append("file", f);

  const { data } = await api
    .put("/web-api/v1/profile/avata", file, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
    .catch((error) => error.response);

  return data;
};

import React from "react";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import { isMobile } from "react-device-detect";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  headerText: {
    fontWeight: 700,
    lineHeight: "32px",
    textAlign: "center",
    letterSpacing: "0.01em",
    [theme.breakpoints.down("xs")]: {
      fontSize: "25px",
      lineHeight: "30px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "24px",
      lineHeight: "32px",
    },
  },
  headerDesc: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "24px",
    },
  },
  titleSpace: {
    padding: 0,
  },
  nameText: {
    marginTop: 16,
    fontWeight: 700,
    lineHeight: "63px",
    textAlign: "center",
    letterSpacing: "0.01em",
    [theme.breakpoints.down("xs")]: {
      fontSize: "25px",
      lineHeight: "30px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "32px",
      lineHeight: "56px",
    },
  },
}));

const EventHeader = ({ name, title, description }) => {
  const classes = useStyles();

  return (
    <>
      <Typography
        variant="h5"
        color="secondary"
        className={clsx(classes.titleSpace, classes.headerText)}
      >
        {title}
      </Typography>

      <Typography
        variant="h5"
        color="primary"
        className={clsx(
          classes.titleSpace,
          classes.headerText,
          classes.headerDesc
        )}
      >
        {description}
      </Typography>

      {name && (
        <Typography
          variant="h5"
          color="secondary"
          className={clsx(classes.titleSpace, classes.nameText)}
        >
          {name}
        </Typography>
      )}
    </>
  );
};

export default EventHeader;

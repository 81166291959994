import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { connect } from "react-redux";
import { actions } from "../../redux/actions";
import { settingClear } from "../../page/games/action";
import { useTranslation } from "react-i18next";

const FormSettingReset = ({ settingDialogClear, userData, ...props }) => {
  const { t } = useTranslation();

  const handleClose = () => {
    props.dispatch({
      type: actions.setting,
      settingDialogClear: false,
    });
  };

  const handleSave = async () => {
    props.dispatch({
      type: actions.setLoading,
      loading: true,
    });

    const res = await settingClear();

    if (res.result) {
      props.dispatch({
        type: actions.setLoading,
        loading: false,
        alertMsg: true,
        alertMessage: res.message,
      });

      props.dispatch({
        type: actions.setting,
        settingDialogClear: false,
      });
    } else {
      props.dispatch({
        type: actions.setInitData,
        loading: false,
        errorText: res.message,
        errorDialog: true,
      });
    }
  };

  return (
    <>
      <Dialog
        open={settingDialogClear}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            background: "rgba(255,255,255,0.98)",
            minWidth: 360,
            padding: 16,
          },
        }}
      >
        <DialogTitle id="form-dialog-title"> {t("menu.confirmclearhistory")}</DialogTitle>

        <DialogContentText style={{ marginLeft: 24 }}>{t("menu.clearall")}</DialogContentText>
        <DialogActions>
          <Button onClick={handleSave} color="primary" variant="contained">
            {t("menu.ok")}
          </Button>
          <Button onClick={handleClose} color="primary">
            {t("menu.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state, dis) => {
  return {
    userData: state.app.userData,
    settingDialogClear: state.setting.settingDialogClear,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(FormSettingReset);

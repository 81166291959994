import { api } from "../../api";

export const login = async ({ username, password }) => {
  const { data } = await api
    .post("web-api/v1/auth/login", JSON.stringify({ username, password }))
    .catch((error) => error.response);

  return data;
};

export const isAuth = async () => {
  const { data } = await api
    .get("web-api/v1/auth/isauth")
    .catch((error) => error.response);
  return data;
};

import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import createSagaMiddleware from "redux-saga";
import {
  compose,
  legacy_createStore as createStore,
  applyMiddleware,
  combineReducers,
} from "redux";
import { middleSaga } from "./redux/saga/middlesaga";
import "./index.css";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import rootReducer from "./redux";

const sagaMiddleware = createSagaMiddleware();
const allReducers = combineReducers({ ...rootReducer });

const outerTheme = createTheme({
  typography: {
    fontFamily: "arial,sans-serif;",
    fontStyle: "normal",
    subtitle1: { fontSize: 18 },
  },
  palette: {
    primary: {
      main: "#880085",
    },
    secondary: {
      main: "#292726",
    },
  },
  overrides: {
    MuiInput: {
      root: {},
      input: {
        color: "#292726",
        fontSize: 18,
        lineHeight: "32px",
        fontWeight: 400,
      },
      textarea: {
        color: "#292726",
        fontSize: 18,
        lineHeight: "32px",
        fontWeight: 400,
      },
    },
    MuiInputLabel: {
      color: "#292726",
      fontWeight: 800,
    },
  },
});

const devtools =
  process.env.NODE_ENV === "development" && window.devToolsExtension
    ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
      window.__REDUX_DEVTOOLS_EXTENSION__()
    : (f) => f;

const store = createStore(
  allReducers,
  compose(applyMiddleware(sagaMiddleware), devtools)
);

sagaMiddleware.run(middleSaga);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <ThemeProvider theme={outerTheme}>
      <App />
    </ThemeProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import Hidden from "@material-ui/core/Hidden";
import Avatar from "@material-ui/core/Avatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PolicyIcon from "@material-ui/icons/Policy";
import LiveTvIcon from "@material-ui/icons/LiveTv";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";
import Chip from "@material-ui/core/Chip";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Grid from "@material-ui/core/Grid";
import { CountDownHourly, CountDownDaily } from "@nilevia/count-down-timer-react";
import { useNavigate } from "react-router-dom";
import { deepOrange, deepPurple } from "@material-ui/core/colors";
import ContactPhoneIcon from "@material-ui/icons/ContactPhone";
import Link from "@material-ui/core/Link";
import moment from "moment";
import FormSettingCredit from "../../component/form/setting_credit";
import FormSettingPassword from "../../component/form/setting_password";
import FormSettingReset from "../../component/form/setting_reset";
import { connect } from "react-redux";
import { actions } from "../../redux/actions";
import { logout } from "../../page/profile/action";
import { formatNumber, getAvata } from "../../utils";
import clsx from "clsx";
import Language from "../../component/form/language";
import Currency from "../../component/form/currency";
import { useTranslation } from "react-i18next";
import AccountBoxIcon from '@material-ui/icons/AccountBox';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  bg: {
    height: "100%",
    width: "100%",
    top: 0,
    left: 0,
    backgroundImage: "url('/photo/scbg.webp')",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundColor: "#121212",
    position: "fixed",
  },
  root: {
    display: "flex",
    zIndex: 1,
    backgroundColor: "#121212",
  },
  appBar: {
    height: "64px",
    minHeight: "64px",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    color: "#fff",
  },
  drawerPaper: {
    width: drawerWidth,
    background: "rgba(18, 18, 18, 0.7)",
    [theme.breakpoints.down("md")]: {
      background: "rgba(18, 18, 18, 0.9)",
    },
  },
  // necessary for content to be below app bar
  toolbar: {
    color: "#fff",
    textAlign: "center",
    paddingTop: 8,
    fontSize: 24,
    fontWeight: 800,
    background: "linear-gradient(180deg,#ffdf46,#ffdc42 23.96%,#feeb52 49.48%,#fbc316 71.87%,#f69b09)",
    "& img": {
      height: 60,
    },
  },
  content: {
    height: "100%",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      marginTop: 52,
    },
  },
  footer: {
    fontSize: 14,
    marginTop: 64,
    marginBottom: 64,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#000000",
    fontWeight: 500,
    [theme.breakpoints.down("xs")]: {
      marginTop: 16,
      marginBottom: 8,
    },
  },
  items: {
    color: "#fff",
    cursor: "pointer",
    padding: 8,
    marginTop: 8,
    "& svg": {
      fill: "#880085",
    },
    "&:hover": {
      background: "linear-gradient(180deg,#ffdf46,#ffdc42 23.96%,#feeb52 49.48%,#fbc316 71.87%,#f69b09)",
      color: "rgba(0,0,0,0.6)",
    },
    "&:hover a": {
      color: "rgba(0,0,0,0.6) !important",
      width: "100%",
    },
  },
  itemsActive: {
    background: "linear-gradient(180deg,#ffdf46,#ffdc42 23.96%,#feeb52 49.48%,#fbc316 71.87%,#f69b09)",
    color: "rgba(0,0,0,0.6)",
  },
  profile: {
    textAlign: "center",
    margin: "0 auto",
    paddingTop: 32,
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
  countdown: {
    color: "#fff",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
    "& svg": {
      fill: "#fff",
    },
  },
}));

const Layout = ({ data, children, style = {}, loading, dispatch }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const { t } = useTranslation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleSignout = async () => {
    dispatch({
      type: actions.setLoading,
      loading: true,
    });
    const lo = await logout();
    if (lo.result) {
      dispatch({
        type: actions.setLoading,
        loading: false,
      });
      navigate("/");
    } else {
      dispatch({
        type: actions.setLoading,
        loading: false,
      });
      navigate("/");
    }
  };

  const handleSetupCredit = () => {
    dispatch({
      type: actions.setting,
      settingDialogCredit: true,
    });
  };

  const handleSetupPassword = () => {
    dispatch({
      type: actions.setting,
      settingDialogPassword: true,
    });
  };

  const handleClear = () => {
    dispatch({
      type: actions.setting,
      settingDialogClear: true,
    });
  };

  const expired = () => {
    handleSignout();
  };

  const avata = getAvata();

  const drawer = (
    <>
      <Hidden smDown implementation="css">
        <div className={classes.toolbar}>
          <img src={avata} alt="" />
        </div>
      </Hidden>
      <Hidden mdUp implementation="css">
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerToggle}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
      </Hidden>

      <div className={classes.profile}>
        <Avatar className={classes.purple} style={{ width: 60, height: 60, margin: "0 auto" }}>
          <AccountCircleIcon style={{ fontSize: 36 }} />
        </Avatar>

        <Chip
          style={{
            color: "#fff",
            fontSize: 16,
            borderColor: "#fff",
            marginTop: 16,
          }}
          avatar={<Avatar style={{ fontSize: 16 }}>฿</Avatar>}
          label={formatNumber(data.balance)}
          variant="outlined"
        />

        <CountDownDaily className={classes.countdown} endDate={moment.utc(data.expired_at).format()} dayAffix="day" hourAffix="hrs" minutesAffix="min" secondAffix="s" onFinish={expired} />

        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Language />
          <Currency />
        </div>
      </div>

      <Divider />
      <div className={clsx(classes.items, classes.itemsActive)} onClick={() => navigate("/games")}>
        <center>
          <img src="/photo/pgslot_menu.png" height={40} alt="" />
        </center>
      </div>

      <Divider />

      <List>
        <ListItem className={classes.items} button onClick={() => handleSetupCredit()}>
          <ListItemIcon>
            <AccountBalanceWalletIcon />
          </ListItemIcon>
          <ListItemText primary={t("menu.settingcredit")} />
        </ListItem>

        <ListItem className={classes.items}>
          <Link
            href="/live"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: "none",
              display: "flex",
              color: "#fff",
              margin: 0,
            }}
            button
          >
            <ListItemIcon>
              <LiveTvIcon />
            </ListItemIcon>
            <ListItemText primary={t("menu.linklive")} />
          </Link>
        </ListItem>

        <ListItem className={classes.items} button onClick={() => handleClear()}>
          <ListItemIcon>
            <DeleteSweepIcon />
          </ListItemIcon>
          <ListItemText primary={t("menu.clearhistory")} />
        </ListItem>

        <ListItem className={classes.items} button onClick={() => handleSetupPassword()}>
          <ListItemIcon>
            <LockOpenIcon />
          </ListItemIcon>
          <ListItemText primary={t("menu.changepwd")} />
        </ListItem>

        <ListItem className={classes.items} button>
          <Link
            href="https://line.me/R/ti/p/@026bfhii"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: "none",
              display: "flex",
              color: "#fff",
              margin: 0,
            }}
            button
          >
            <ListItemIcon>
              <ContactPhoneIcon />
            </ListItemIcon>
            <ListItemText primary={t("menu.contract")} />
          </Link>
        </ListItem>

        <ListItem className={classes.items} button>
          <Link
            href="/privacy"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: "none",
              display: "flex",
              color: "#fff",
              margin: 0,
            }}
            button
          >
            <ListItemIcon>
              <PolicyIcon />
            </ListItemIcon>
            <ListItemText primary={t("menu.poliycy")} />
          </Link>
        </ListItem>


        <ListItem className={classes.items} button>
          <Link
            href="/profile"
            style={{
              textDecoration: "none",
              display: "flex",
              color: "#fff",
              margin: 0,
            }}
            button
          >
            <ListItemIcon>
              <AccountBoxIcon />
            </ListItemIcon>
            <ListItemText primary={t("menu.settingprofile")} />
          </Link>
        </ListItem>

        <ListItem className={classes.items} onClick={() => handleSignout()} button>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary={t("menu.signout")} />
        </ListItem>
      </List>
    </>
  );

  return (
    <div className={classes.root}>
      <div className={classes.bg} />
      <CssBaseline />
      <FormSettingCredit />
      <FormSettingPassword />
      <FormSettingReset />

      <Hidden mdUp implementation="css">
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: mobileOpen,
          })}
        >
          <Toolbar style={{ height: 64 }}>
            <IconButton color="inherit" onClick={handleDrawerToggle} edge="start" className={clsx(classes.menuButton, mobileOpen && classes.hide)}>
              <MenuIcon />
            </IconButton>

            <img src={avata} alt="pgjazz.win" height={50} style={{ textAlign: "center", margin: "0 auto", display: "flex", justifyContent: "center" }} />
          </Toolbar>
        </AppBar>
      </Hidden>

      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="left"
          open={mobileOpen}
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper,
          }}
          onClick={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor="left"
          open
          variant="permanent"
        >
          {drawer}
        </Drawer>
      </Hidden>
      <div style={style} className={classes.content}>
        {children}
      </div>

      {/* <Grid item xs={12} className={classes.footer}>
        Powered by&nbsp;
        <img src="/logo/logo.png" alt="pg" width="28.34" />
      </Grid> */}
    </div>
  );
};

const mapStateToProps = (state, dis) => {
  return {
    loading: state.app.loading,
    errorText: state.app.errorText,
    errorDialog: state.app.errorDialog,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);

import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import DraftsIcon from "@material-ui/icons/Drafts";
import SendIcon from "@material-ui/icons/Send";
import { useTranslation } from "react-i18next";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const Language = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [l, setL] = React.useState("th");

  const { i18n } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLang = async (lang) => {
    window.localStorage.setItem("win_lang", lang);
    setL(lang);
    setAnchorEl(null);

    await i18n.changeLanguage(lang);
  };

  useEffect(() => {
    const lng = localStorage.getItem("win_lang") || "th";
    setL(lng);
  }, []);

  return (
    <div>
      <Button aria-controls="customized-menu" style={{ marginBottom: 8 }} aria-haspopup="true" variant="contained" color="primary" onClick={handleClick} endIcon={<ExpandMoreIcon />}>
        <img src={`/logo/${l}.png`} alt={l} height={30} />
      </Button>
      <StyledMenu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <StyledMenuItem selected={l === "th"} onClick={() => handleLang("th")}>
          <ListItemIcon>
            <img src="/logo/th.png" alt="th" height={30} />
          </ListItemIcon>
          <ListItemText primary="ภาษาไทย" />
        </StyledMenuItem>
        <StyledMenuItem selected={l === "en"} onClick={() => handleLang("en")}>
          <ListItemIcon>
            <img src="/logo/en.png" alt="en" height={30} />
          </ListItemIcon>
          <ListItemText primary="English" />
        </StyledMenuItem>
        <StyledMenuItem selected={l === "zh"} onClick={() => handleLang("zh")}>
          <ListItemIcon>
            <img src="/logo/zh.png" alt="zh" height={30} />
          </ListItemIcon>
          <ListItemText primary="Chinese" />
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
};

export default Language;

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "./locales/en/translation.json";
import translationTH from "./locales/th/translation.json";

const resources = {
  en: {
    translation: translationEN,
  },
  th: {
    translation: translationTH,
  },
  zh: {
    translation: translationEN,
  },
};

const lng = localStorage.getItem("win_lang") || "th";

i18n.use(initReactI18next).init({
  resources,
  lng,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;

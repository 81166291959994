import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import DraftsIcon from "@material-ui/icons/Drafts";
import SendIcon from "@material-ui/icons/Send";
import { updateCurrency } from "../../page/profile/action";
import { connect } from "react-redux";
import { actions } from "../../redux/actions";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const Currency = ({ userData, ...props }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [s, setS] = React.useState(userData.symbol);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCurrency = async (symbol) => {
    const cy = {
      "฿": "THB",
      $: "USD",
      "¥": "CNY",
      K: "MMK",
      "₱": "PHP",
      Rp: "IDR",
      R$: "BRL",
    };

    props.dispatch({
      type: actions.setLoading,
      loading: true,
      userData: { ...userData, symbol, currency: cy[symbol] },
    });

    setS(symbol);

    const res = await updateCurrency({
      symbol,
      currency: cy[symbol],
    });

    if (res.result) {
      props.dispatch({
        type: actions.setLoading,
        loading: false,
        userData: { ...userData, symbol, currency: cy[symbol] },
      });
    } else {
      props.dispatch({
        type: actions.setLoading,
        loading: false,
        errorText: res.message,
        errorDialog: true,
      });
    }

    setAnchorEl(null);
  };

  return (
    <div>
      <Button aria-controls="customized-menu" style={{ marginBottom: 8 }} aria-haspopup="true" variant="contained" color="primary" onClick={handleClick} endIcon={<ExpandMoreIcon />}>
        {userData.symbol}
      </Button>

      <StyledMenu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <StyledMenuItem selected={userData.symbol === "฿"} onClick={() => handleCurrency("฿")}>
          <ListItemText primary="THB ฿" />
        </StyledMenuItem>
        <StyledMenuItem selected={userData.symbol === "$"} onClick={() => handleCurrency("$")}>
          <ListItemText primary="USD $" />
        </StyledMenuItem>
        <StyledMenuItem selected={userData.symbol === "¥"} onClick={() => handleCurrency("¥")}>
          <ListItemText primary="CNY ¥" />
        </StyledMenuItem>
        <StyledMenuItem selected={userData.symbol === "K"} onClick={() => handleCurrency("K")}>
          <ListItemText primary="MMK K" />
        </StyledMenuItem>
        <StyledMenuItem selected={userData.symbol === "₱"} onClick={() => handleCurrency("₱")}>
          <ListItemText primary="PHP ₱" />
        </StyledMenuItem>
        <StyledMenuItem selected={userData.symbol === "Rp"} onClick={() => handleCurrency("Rp")}>
          <ListItemText primary="IDR Rp" />
        </StyledMenuItem>
        <StyledMenuItem selected={userData.symbol === "R$"} onClick={() => handleCurrency("R$")}>
          <ListItemText primary="BRL R$" />
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
};

const mapStateToProps = (state, dis) => {
  return {
    userData: state.app.userData,
  };
};

export default connect(mapStateToProps)(Currency);

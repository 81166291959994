import Cookie from "cookie-universal";
import moment from "moment";
const cookies = Cookie();

export const getToken = () => cookies.get("game888");

export const getAvata = () => cookies.get("avata888") || "/logo/slot.png";

export const setCookie = (key, value) => cookies.set(key, value);

export const unmaskTaxid = (text = "") => {
  const match = !!text ? text.match(/\d+[^\s\S]?/g) : false;
  if (match) {
    return match.reduce((total, value) => `${total}${value}`);
  }
  return text;
};

export const validateTaxid = (taxid) => {
  const umask = unmaskTaxid(taxid);
  const validLen = /^[0-9]{13}$/.test(umask);
  let validCheckSum = false;
  if (validLen) {
    const numbers = umask.match(/\d/g);
    const sum1To12 = numbers
      .filter((n, i) => i < numbers.length - 1)
      .map((n, i) => n * (14 - i - 1))
      .reduce((total, val) => total + val);
    const x = sum1To12 % 11;
    const N13 = x > 1 ? 11 - x : 1 - x;
    validCheckSum = +numbers[numbers.length - 1] === N13;
    // console.warn({ validCheckSum, x, N13, taxid });
  }
  return validLen && validCheckSum;
};

export const validateEmail = (email) => {
  // const regex =
  //   /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/g;
  const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  return regex.test(email);
};

export const validatePhone = (number) => {
  const regexMobile = /^((0)\d{9})/;
  // const regexMobile =
  //   /^(\d{9,10})|(\+66\d{9}|\+66\d{1}-\d{4}-\d{4})|(\+66[-\s]?0?|0)[689]{1}[0-9]{1}[-\s]?\d{3}[-\s]?\d{4}/;
  const str = number.replace(/(\s|-)|(\)|\()/g, "");
  const m = regexMobile.exec(str);
  let result = false;
  if (m !== null) {
    result = true;
  }
  return result;
};

export const html_substr = (html, len) => {
  var temp = html.substr(0, len);
  if (temp.lastIndexOf("<") > temp.lastIndexOf(">")) {
    temp = html.substr(0, 1 + html.indexOf(">", temp.lastIndexOf("<")));
  }
  return temp;
};

export const dateTH = (date) =>
  new Date(date).toLocaleDateString("th-TH", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

export const formatNumber = (n) => {
  try {
    return n.toLocaleString();
  } catch (error) {
    return 0;
  }
};

export const actions = {
  initData: "APP_INITDATA",
  setLogOut: "APP_SETLOGOUT",
  setInitData: "APP_SETINITDATA",
  loading: "APP_LOADING",
  setLoading: "APP_SETLOADING",
  games: "APP_GAMES",
  setGames: "APP_SETGAMES",
  setting: "APP_SETTING",
  setAlertMsg: "APP_ALERTMSG",
};

import { actions } from "./actions";

const initialState = {
  settingDialog: false,
  giData: {},
  settingDialogCredit: false,
  settingDialogPassword: false,
  settingDialogClear: false,
};

export default function setting(state = initialState, action) {
  const { type, ...etc } = action;
  switch (type) {
    case actions.setting:
      return { ...state, ...etc };
    default:
      return state;
  }
}
